document.addEventListener('DOMContentLoaded', async () => {
    const eventsContainer = document.querySelector('.sc-results');
    if (eventsContainer) {
        UpComingContainer = document.querySelector('.all-upcoming');
        myUpComingContainer = document.querySelector('.my-upcoming');
        toggleLoadingState(myUpComingContainer, true);
        toggleLoadingState(UpComingContainer, true);
        getMypUpcoming();
        getAllUpcoming();
    }
});

async function getAllUpcoming() {
    try {
        const Allresponse = await fetch('/api/all-upcoming', {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Content-Type': 'application/json'
            }
        });

        if (!Allresponse.ok) {
            throw new Error(`HTTP error! Status: ${Allresponse.status}`);
        }

        const data = await Allresponse.json();
        events = data.data.events
        fillAllUpcoming(events);

        allUpComingContainer = document.querySelector('.all-upcoming');
        toggleLoadingState(allUpComingContainer, false);

    } catch (error) {
        console.error('Error fetching tags:', error);
    }
}

function fillAllUpcoming(events) {
    var allUpComingContainer = $('#all-upcoming-container');
    allUpComingContainer.empty();
    var options = { month: 'long', year: 'numeric' };
    if(events.length != 0) {
        events.forEach(function(event) {
            var date = new Date(event.date_formatted);
            var register = '';
            if (event.register_url != '') {
                register = `
                    <a class="icon-a" href="${event.register_url}" target="_blank"><img src="/images/icons/register.svg"> Register</a>
                    <a class="icon-a share-button" href="#" data-url="${event.register_url}"><img src="/images/icons/share.svg"> Share</a>
                `
            }
            allUpComingContainer.append(`
                <div class="row sc-results__upcoming__race">
                    <div class="col-2 sc-results__upcoming__race__date">
                        <span>${date.getDate()}</span>
                        <span>${date.toLocaleDateString("en-US", options).toUpperCase()}</span>
                    </div>
                    <div class="col">
                        <div>
                            <span class="sc-results__upcoming__race__tour ${event.type == "challengers" ? "sc-results__upcoming__race__tour__cyan" : ""}">${event.type.toUpperCase()}</span>
                        </div>
                        <div class="sc-results__upcoming__race__title">
                            <span>${event.name}</span>
                        </div>
                        <div>
                            <span class="sc-results__upcoming__race__info">
                                ${event.country}, ${event.location}
                                ${event.distance} ${event.technique}
                            </span>
                            <span class="sc-results__upcoming__race__share">`
                                + register +
                            `</span>
                        </div>
                    </div>
                </div>
            `);
        });
        $('.share-button').on('click', function(e) {
            e.preventDefault();
            var shareURL = $(this).data('url');
            if (shareURL) {
                const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`;
                window.open(fbShareUrl, '_blank', 'width=600,height=400');
            } else {
                alert('No URL to share!');
            }
        });
    }
}

async function getMypUpcoming() {
    try {
        const myResponse = await fetch('/api/my-upcoming', {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Content-Type': 'application/json'
            }
        });

        if (!myResponse.ok) {
            throw new Error(`HTTP error! Status: ${myResponse.status}`);
        }

        const data = await myResponse.json();
        events = data.data.response.events
        fillMyUpcoming(events);
        myUpComingContainer = document.querySelector('.my-upcoming');
        toggleLoadingState(myUpComingContainer, false);

    } catch (error) {
        console.error('Error fetching tags:', error);
    }
}

function fillMyUpcoming(events) {
    var myUpComingContainer = $('#my-upcoming-container');
    myUpComingContainer.empty();
    var options = { month: 'long', year: 'numeric' };
    if(events.length != 0) {
        events.forEach(function(event) {
            var date = new Date(event.date);
            myUpComingContainer.append(`
                <div class="row sc-results__upcoming__race">
                    <div class="col-2 sc-results__upcoming__race__date">
                        <span>${date.getDate()}</span>
                        <span>${date.toLocaleDateString("en-US", options).toUpperCase()}</span>
                    </div>
                    <div class="col">
                        <div>
                            <span class="sc-results__upcoming__race__tour ${event.type == "challengers" ? "sc-results__upcoming__race__tour__cyan" : ""}">
                                ${event.type ? (event.type ? event.type.toUpperCase() : "") : event.technique.toUpperCase()}</span>
                        </div>
                        <div class="sc-results__upcoming__race__title">
                            <span>${event.name}</span>
                        </div>
                        <div>
                            <span class="sc-results__upcoming__race__info">
                                ${event.country}, ${event.location}
                                ${event.distance} ${event.technique}
                            </span>
                            <span class="sc-results__upcoming__race__share">
                                <a class="icon-a" href="#"><img src="/images/icons/share.svg"> Share</a>
                            </span>
                        </div>
                    </div>
                </div>
            `);
        })
    } else {
        var today = new Date();
        myUpComingContainer.append(`
            <div class="row sc-results__upcoming__race">
                <div class="col-2 sc-results__upcoming__race__date">
                    <span>${today.getDate()}</span>
                    <span>${today.toLocaleDateString("en-US", options)}</span>
                </div>
                <div class="col">
                    <div>
                        <span class="sc-results__upcoming__race__tour">This is where your upcoming events will be displayed</span>
                    </div>
                    <div class="sc-results__upcoming__race__title">
                        <span>You don't have any upcoming events</span>
                    </div>
                    <div>
                        <span class="sc-results__upcoming__race__info">
                            You can sign up for events
                            in upcoming events
                        </span>
                        <span class="sc-results__upcoming__race__share">
                        </span>
                    </div>
                </div>
            </div>
        `)
    }

}

function toggleLoadingState(parent, isLoading) {
    const spinner = parent.querySelector('.spinner-border');
    const buttons = parent.querySelectorAll('.sc-button');
    if (isLoading) {
        spinner.classList.remove('d-none');
        buttons.forEach(button => button.classList.add('d-none'));
    } else {
        spinner.classList.add('d-none');
        buttons.forEach(button => button.classList.remove('d-none'));
    }
}