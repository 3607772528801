function ios_tooltip(){

	

	$('.ios-homescreen--close').on('click',function(e){
		createCookie('ios-homescreen', false, 1);
		$('.ios-homescreen').removeClass('active');
	});

	//https://www.netguru.com/codestories/few-tips-that-will-make-your-pwa-on-ios-feel-like-native
	var isIos = false;
	// Detects if device is on iOS 

	var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
	navigator.userAgent &&
	navigator.userAgent.indexOf('CriOS') == -1 &&
	navigator.userAgent.indexOf('FxiOS') == -1;


	window.isInStandaloneMode = checkInStandaloneMode();
	// Checks if should display install popup notification:
	if (checkIos() && !window.isInStandaloneMode) {
		var isIos = true;
	}

	if(isSafari && isIos && getCookie('ios-homescreen') != 'false'){
		$('.ios-homescreen').addClass('active');
	}

	if(window.isInStandaloneMode){
		
		use_ajax_page_load();	
	}

}

// Detects if device is in standalone mode
function checkInStandaloneMode(){
	return ('standalone' in window.navigator) && (window.navigator.standalone);
}
function checkIos(){
	var userAgent = window.navigator.userAgent.toLowerCase();
	return /iphone|ipad|ipod/.test( userAgent );
}


function use_ajax_page_load(){
	$('a').on('click', function (e){
		e.preventDefault();
		load_ajax(e);

	})
}


function load_ajax(e){
	var url = $(e.target).attr('href');
	
	if(url.indexOf(homeUrl) != -1 && $(e.target).attr('target') != '_blank' ){
		$('main').addClass('fade-out');

		setTimeout(function(){ 
			$.ajax({
				type:'GET',
				url: $(e.target).attr('href'),
				dataType: "html",
				async: false,
				cache: false,

				beforeSend: function () {




				},
				success: function(response)
				{

					var $dom = $(document.createElement("html"));
					$dom[0].innerHTML = response;
					var $body = $dom.find("body");
					
					$('body').html($body.contents().unwrap()); 

					$('main').addClass('fade-in');
					use_ajax_page_load(); 
					event_listeners();    

					setTimeout(function(){ 
						$('main').removeClass('fade-in');
						$('main').removeClass('fade-out');
					}, 1);





				}
			});
		}, 20);


		

	}

	if( $(e.target).attr('target') == '_blank'){
		var win = window.open(url, '_blank');
		
	}
	
}