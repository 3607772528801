$(document).ready(function() {
	
});


function ranking_list_athlete(){
	var rankinglistAthlete = $('.scroll_to_rank__button');
	if(rankinglistAthlete.length ){
		$(rankinglistAthlete).on('click', function (){

			let layer = parseInt($(this).attr('data-layer'))+1;
			let personID = $(this).attr('data-personID');
			let rank = $(this).attr('data-rank');
			jump_to_place(rank);
			
		});

	}
}


function ranking_search(){
	var query = combine_ranking_query();
	if(query.length == 0){
		query +='name='+ $('#ranking-search').val();
	}else{
		query +='&name='+ $('#ranking-search').val();
	}

	if($('#ranking-search').val().length != 0) {
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		$.ajax({
			type: 'POST',
			url: '/ajax/search_ranking?'+query,
			beforeSend: function() {
				$('#ranking-search-results').html('Searching...').addClass('active');
				$('.ranking__search').addClass('active');
				$('.ranking__filter__content--toggle').prop('checked', false);
				if($('#rank-filter-options').prop('checked')){
					$('.ranking__filter > .chevron').addClass('active');
					
				}else{
					$('.ranking__filter > .chevron').removeClass('active');
				}


			},
			success: function (data) {

				$('#ranking-search-results').html(data.msg)
					.addClass('active overlay');

				$('#ranking-search-results + .overlay').on('click', function (){
					$('#ranking-search-results').html('').removeClass('active');
					$('#ranking-search').val("");
					$('.ranking__search').removeClass('active');
				})

				$('#ranking-search-results .scroll_to_rank__button').on('click', function (){
					$('#ranking-search-results').html('').removeClass('active');
					$('#ranking-search').val("");
					$('.ranking__search').removeClass('active');

					jump_to_place($(this).attr('data-rank'));
					
				})
				
				

			}
		});
	} else {
		$('#ranking-search-results').empty();
	}
}

function pagination_event(){

	var rank_page = $('.rank-page');
	if(rank_page.length){
		rank_page.on('click', function(){
			var page = $(this).attr('data-page');
			var is_next = $('#rank-page').attr('data-page') < page;
			$('#rank-page').attr('data-page',page);
			ranking_list_init(is_next);
		})
	}

}

function ranking_filter_event(){
	
	var gender_ranking = $('#rank-gender');
	var gender_years = $('#rank-years');
	var gender_nationality = $('#rank-nationality');
	var rank_favorites = $('#rank-favorites');

	if(gender_ranking.length){
		gender_ranking.on('change', function(){
			$('#rank-page').attr('data-page', 1);
			ranking_list_init();	
		});
	}
	if(gender_years.length){
		gender_years.on('change', function(){
			$('#rank-page').attr('data-page', 1);
			ranking_list_init();	
		});
	}
	if(gender_nationality.length){
		gender_nationality.on('change', function(){
			$('#rank-page').attr('data-page', 1);
			ranking_list_init();	
		});
	}

	if(rank_favorites.length){
		rank_favorites.on('change', function(){
			$('#rank-page').attr('data-page', 1);
			ranking_list_init();
		})
	}
	
}

function rank_card_load_ajax(){
	if(window.isInStandaloneMode){
		$('#temp-ranking').find('a:not(.rank-card__favorite)').on('click', function (e){
			e.preventDefault();
			load_ajax(e);
		})
	}
}

function ranking_list_init(forward = null){
	var ranking_list = $('#temp-ranking');
	$('#ranking-search-results').html('');
	$('#ranking-search').val('');

	if(ranking_list.length){
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
		$.ajax({
			type: 'POST',
			url: '/ajax/loadRankingList?'+combine_ranking_query(),
			beforeSend: function() {

				$('.ranking-loader').addClass('active');

			},
			success: function (data) {
				$('.ranking-loader').removeClass('active');
				$(ranking_list).html(data.msg);

				rank_card_load_ajax();


				if(data.totalAmount){
					$('#rank-totalAmount').attr('data-total', data.totalAmount);
				}


				//Set rank on user
				if(data.user_rank){
					$('.current-user').attr('data-rank', data.user_rank);
					$('.current-user .rank').html( data.user_rank);
				}else{
					$('.current-user').attr('data-rank', false);
					$('.current-user .rank').html('N/A');


				}
				pagination_event();
				toggle_favorite();
				if(forward !== null && forward){
					$('html,body').animate({
						scrollTop: $("#temp-ranking .rank-card").first().offset().top - 60
					}, 'fast');

				}
			}
		});

	}
}

function combine_ranking_query(){
	var gender = $('#rank-gender').val();
	var years = $('#rank-years').val();
	var nationality = $('#rank-nationality').val();
	var page = $('#rank-page').attr('data-page');
	var favorites = $('#rank-favorites').is(':checked');



	var query = "";
	if(gender && gender != 'all'){
		if(query.length > 0){
			query += '&';
		}
		query += 'gender='+gender;

	}
	if(years && years != 'all'){
		if(query.length > 0){
			query += '&';
		}
		query += 'years='+years;
	}

	if(nationality && nationality != 'all'){
		if(query.length > 0){
			query += '&';
		}
		query += 'nationality='+nationality;
	}

	if(page){
		if(query.length > 0){
			query += '&';
		}
		query += 'page='+page;
	}

	if(favorites){
		if(query.length > 0){
			query += '&';
		}
		query += 'favorites=true';
	}

	if (history.pushState) {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+query;
		window.history.pushState({path:newurl},'',newurl);
	}
	return query;


}
function jump_to_place(rank){
	/* 
	IF the search rank is more than total then set it to the total amount
	if rank is less than 1 then set it to 1;
	*/
	var parent = $('.ranking-list__footer');
	toggleLoadingStateRanking(parent, true)
	var query = combine_ranking_query();
	if($('#rank-totalAmount').length){
		var totalAmount = $('#rank-totalAmount').attr('data-total');
		if(totalAmount){
			if(parseInt(rank) > parseInt(totalAmount)){
				rank = totalAmount;
				$('#jump_to_rank').val(rank);
			}

		}
	}
	if(parseInt(rank) < 1 ){
		rank = 1;
		$('#jump_to_rank').val(rank);

	}
	

	query +='&rank='+rank;

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$.ajax({
		type: 'POST',
		url: '/ajax/loadRankingList?'+query,
		beforeSend: function() {
			$('.ranking-loader').addClass('active');

		},
		success: function (data) {
			toggleLoadingStateRanking(parent, false)
			$('.ranking-loader').removeClass('active');
			$('#temp-ranking').html(data.msg);
			rank_card_load_ajax();
			$('#rank-page').attr('data-page',data.page);
			pagination_event();
			combine_ranking_query();

			if(parseInt(rank) == 1){
				$('html,body').animate({
					scrollTop: 0
				}, 'fast');

			}else{
				if($(".sc-ranking-card[data-rank='"+rank+"']").length){
					$('html,body').animate({
						scrollTop: $(".sc-ranking-card[data-rank='"+rank+"']").first().offset().top - ($(window).height() / 2)
					}, 'fast');
				}
			}

			
			$("#temp-ranking .rank-card[data-rank='"+rank+"']").addClass('highlight');
			setTimeout(function(){
				$("#temp-ranking .rank-card[data-rank='"+rank+"']").removeClass('highlight');
			}, 2000);
			toggle_favorite();


		}
	});

}

function toggleLoadingStateRanking(parent, isLoading) {
    var spinner = parent.find('.spinner-border');
    if (isLoading) {
		spinner.removeClass('d-none');
    } else {
		spinner.addClass('d-none');
    }
}

function jump_to_place_event(){
	var current_user= $('.current-user');
	if(current_user.length){
		current_user.on('click', function(){
			var rank = parseInt($(this).attr('data-rank'));
			if(rank && Number.isInteger(rank)){
				if(rank){
					jump_to_place(parseInt(rank));	
				}
				
			}
		})
	}



	var rank_input = $('#jump_to_rank');
	if(rank_input.length){
		rank_input.on('change', function(){
			var rank = $(this).val();
			
			if(rank){
				jump_to_place(rank);	
			}
			
		})
	}
}

function toggle_favorite(){
	$('.rank-card__favorite').on('click',  function(){
		var parent = $(this);
		let imgTag = $(this).find('img');
		var personID = imgTag.attr('data-id');

		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		$.ajax({
			type: 'POST',
			url: '/ajax/toggle_favorite/'+personID,
			beforeSend: function() {
				togglesource(parent)
			},
			success: function (data) {
			}
		});
	})
}

function togglesource(parent) {
	const imgTag = $(parent).find('img');
	const currentSrc = imgTag.attr('src');
	const src1 = '/images/icons/heart_red_outline.svg'
	const src2 = '/images/icons/heart_red.svg'
	if (currentSrc === src1) {
        imgTag.attr('src', src2);
    } else {
        imgTag.attr('src', src1);
    }
}

function rank_filter_text(){
	var filter_text= "";

	if($('#rank-favorites').is(':checked')){
		
		filter_text += 'FAVORITES,';

	}
	var gender = $('#rank-gender').val();
	if(gender && gender.toLowerCase() != 'all'){
		if(gender == 'W'){
			gender = 'WOMEN';
		}else{
			gender = 'MEN';
		}
		filter_text += ' '+ gender+',';
	}
	var years = $('#rank-years option:selected').html();
	if(years && years.toLowerCase() != 'all'){
		filter_text += ' '+ years+',';
	}
	var nationality = $('#rank-nationality').val();
	if(nationality && nationality.toLowerCase() != 'all'){
		filter_text += ' '+ nationality+',';
	}
	
	filter_text = filter_text.substring(0, filter_text.length - 1);

	if(filter_text.length){
		filter_text +='';
	}

	
	$('#rank-filter--text').html(filter_text);
}

function event_listeners_ranking(){
	ranking_list_athlete();
	jump_to_place_event();
	ranking_filter_event();
	ranking_list_init();
	toggle_favorite();
	rank_filter_text();

	$('#rank-filter-options').on('change', function(){
		if($(this).prop('checked')){
			$('.ranking__filter > .chevron').addClass('active');
			
		}else{
			$('.ranking__filter > .chevron').removeClass('active');
		}
	});

	$('#rank-favorites, #rank-gender,  #rank-years,#rank-nationality ').on('change', function(){
		rank_filter_text();
	})


	$('.ranking__filter__content--toggle').on('change', function(){
		$('#ranking-search, #ranking-search-results').removeClass('active');
		$('#ranking-search-results').html('');
	})

	$('.rank-filter-overlay').on('click', function(){
		$('#ranking-search, #ranking-search-results').removeClass('active');
		$('#ranking-search-results').html('');
		$('#ranking-search-results').html('').removeClass('active');
		$('#ranking-search').val("");
		$('.ranking__search').removeClass('active');
		$('#rank-filter-options').prop('checked', false);
		if($('#rank-filter-options').prop('checked')){
			$('.ranking__filter > .chevron').addClass('active');
			
		}else{
			$('.ranking__filter > .chevron').removeClass('active');
		}
	});

	/*
		Search skier in ranking
		*/
	var typingTimer;                //timer identifier
	var doneTypingInterval = 1000;  //time in ms (5 seconds)
	$('#ranking-search').keyup(function(){
		clearTimeout(typingTimer);
		typingTimer = setTimeout(function(e){
			ranking_search();
		}, doneTypingInterval);

	});
}