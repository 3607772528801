document.querySelectorAll(".sc-pin-popup").forEach(e => {
    e.onclick = () => {
        let html = `<div class="modal modal-dialog-centered sc-pin-modal" id="pinModal" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">${e.dataset.name}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <img class="sc-pin-big" src="${e.getElementsByTagName('img')[0].src}"><br><br>
                                    <p>${e.dataset.desc}</p>
                                    <p>${e.dataset.hidden}</p>
                                    <button class="sc-button">
                                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://mypages.skiclassics.com/sharer/pin/${e.dataset.id}/&quote=I've collected the ${e.dataset.name} pin!" 
                                            target="_blank" 
                                            rel="noreferrer"
                                            style="color: white; text-decoration: none;">
                                            Share
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>`;
        let modal = document.createElement("div");
        modal.innerHTML = html;
        document.body.appendChild(modal);
        modal.id = "modalBody";
        var pinModal = new bootstrap.Modal(document.getElementById('pinModal'));
        pinModal.show();
        modal.addEventListener("hide.bs.modal", e => {
            document.getElementById("modalBody").remove();
        });
    }
});
