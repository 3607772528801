$(document).ready(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $('#proam-search').on('input', function() {
        let query = $(this).val();
        if (query.length > 0) {
            $.ajax({
                url: "/proam-search",
                type: "GET",
                data: { query: query },
                success: function(data) {
                    let resultsDiv = $('#results');
                    resultsDiv.empty();
                    if (data.length > 0) {
                        data.forEach(function(user) {
                            if (user.image) {
                                profilePicture = `<img alt="User Profile Image" class="proam-flag proam-pic" src="${$user.image}"></img>`
                            } else {
                                profilePicture = `<img alt="User Profile Image" class="proam-flag proam-pic" src="/images/profile.png">`
                            }
                            if (user.country) {
                                user.country = user.country.toLowerCase();
                                country = `
                                    <div class="col-1 ms-1 g-0">
                                        <img class="proam-flag" src="/images/flags/${user.athlete_country}.svg">
                                    </div>
                                `
                            } else {
                                country = '<div class="col-1"></div>'
                            }
                            if (user.invited == true) {
                                userStatus = `
                                    <div class="col-2 g-0 d-flex align-items-center">
                                        <div class="g-0 p-0 proam-pending">PENDING</div>
                                    </div>
                                `
                            } else {
                                userStatus = `
                                    <div class="col-2 g-0 d-flex align-items-center">
                                        <button id="invite-member" class="pro-am-add invite-member" data-user-id="${user.id}">ADD</button>
                                    </div>
                                `
                            }
                            if (!user.sc_rank) {
                                user.sc_rank = 'N/A'
                            }
                            resultsDiv.append(`
                                <div class="row pb-2 mt-3 search-card">
                                    <div class="col-1 p-0 d-flex align-items-center">`
                                        + profilePicture +
                                    `</div>
                                    <!--<div class="col-1">${user.sex}</div>-->`
                                    + country +
                                    `<div class="col">
                                        <div class="row">
                                            <div class="proam-bold">${user.athlete_name} ${user.athlete_surname}</div>
                                            <div class="proam-small">${user.sex} / ${user.year}</div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-12 proam-small">SC RANKING</div>
                                            <div class="col-12 proam-bold">#${user.sc_rank}</div>
                                        </div>
                                    </div>`
                                    + userStatus +
                                `</div>
                            `);
                        });
                    } else {
                        resultsDiv.append('<div>No results found</div>');
                    }
                },
                error: function() {
                    console.error("An error occurred during the search.");
                }
            });
        } else {
            $('#results').empty();
        }
    });
    $(document).on('click', '#invite-member', function() {
        const button = $(this);
        var userId = $(this).data('user-id');
        console.log('click')
        console.log(userId)
        $.ajax({
            url: '/invite-member',
            method: 'POST',
            data: {
                user_id: userId,
            },
            success: function(response) {
                $('#proam-search').trigger('input'); 
                // alert(response.message);
            },
            error: function(xhr, status, error) {
                alert("Error adding user: " + error);
            }
        });
        window.location.reload();
    });
    $('#proam-teamsearch').on('input', function() {
        let query = $(this).val();
        if (query.length > 0) {
            $.ajax({
                url: "/proam-teamsearch",
                type: "GET",
                data: { query: query },
                success: function(data) {
                    let resultsDiv = $('#teamresults');
                    resultsDiv.empty();
                    if (data.length > 0) {
                        data.forEach(function(team, index) {
                            if(team.team_image_name) {
                                teamImage = `<img alt="Team Profile Image" class="pro-am-profilbild" src="/uploads/team-images/${team.team_image_name}"></img>`;
                            } else {
                                teamImage = `<img alt="Team Profile Image" class="pro-am-profilbild" src="/images/profile.png">`;
                            }
                            resultsDiv.append(`
                                <div class="container g-0">
                                    <div class="row g-0 mt-2 pb-1 search-card">
                                        <div class="col-2 d-flex justify-content-center align-items-center">`
                                        + teamImage +
                                        `</div>
                                        <div class="col-2">
                                            ${index + 1}
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <!-- TODO FIX FLAG -->
                                                <!-- <img class="proam-flag" src="/images/flags/{{$currentTeam->nationality}}.png">--> ${team.team_name.toUpperCase()}
                                                </div>
                                                <div class="col-12 proam-small">
                                                    ${team.team_score} POINTS
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2"></div>
                                    </div>
                                </div>
                            `);
                        });
                    } else {
                        resultsDiv.append('<div>No results found</div>');
                    }
                },
                error: function() {
                    console.error("An error occurred during the search.");
                }
            });
        } else {
            $('#teamresults').empty();
        }
    });
});