$(document).ready(function() {



	ios_tooltip();
	event_listeners();

	




});





function info_toggler_event_listener(){
	$('.info-toggler').off('click');
	$('.info-toggler').on('click', function(e){
		

		if(!$(e.target).hasClass('fa-star')){
			if($(this).hasClass('close')){
				$('#content-info').html('');
			}else{
			//$('.content-info[data-id="'+$(this).attr('data-id')+'"][data-type="'+$(this).attr('data-type')+'"]').toggleClass('active');
			$.ajaxSetup({
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				}
			});
			$.ajax({
				type: 'POST',
				url: '/ajax/content-info/' + $(this).attr('data-id') + '/' + $(this).attr('data-type'),
				success: function (data) {
					$('#content-info').html(data.msg);
					info_toggler_event_listener();

				}
			})
		}

	}








});

}

function toggle_criteria_labels(){
	var labels = $('label.toggle__criteria');
	if(labels.length){
		$( labels).each(function() {
			var labels_for = $(this).attr('for')
			var input = $('input[type="checkbox"][name="'+labels_for+'"]');
			if(input.is(':checked')){
				$(this).html('Deactivate');
			}else{
				$(this).html('Activate');
			}
			
		});
	}
}

function general_pin_criteria_text(){
	var type = $('#general_pin_type').val();
	var race = "For ";
	$('#general_nr_of_times_div').addClass('hide');
	var general_nr_of_times_show_races = true;
	var general_nr_of_times_show_criteria = true;
	var race_comparison = 'AND';
	if(type == 'race'){
		race_comparison  = $('#general_race_ref_comparison').val();

		var race_ids = $('select[name="general_race_ref[]"]').val();
		if(race_ids){
			for(var i = 0; i < race_ids.length ; i++){
				race += $.trim($('option[value="'+race_ids[i]+'"]').html()) + ' (any year) '+race_comparison+' ';
			}
			race = race.substring(0, race.length-5);
			race += ':  ';

		}
	}else if(type == 'specific_race'){
		race_comparison  = $('#general_race_comparison').val();

		var race_ids = $('select[name="general_race[]"]').val();
		general_nr_of_times_show_races = false

		if(race_ids){
			for(var i = 0; i < race_ids.length ; i++){
				race += $.trim($('option[value="'+race_ids[i]+'"]').html()) + ' '+race_comparison+' ';
			}	
			race = race.substring(0, race.length-5);
			race += ':  ';
		}

	}else if(type == 'season'){

		race = 'Season '+ $('#season').val() + ' ';
		if($('#general_season_series').val() != 'all'){
			race +='series: '+ $('#general_season_series').val() + ' - ';
		}
	}else if(type == 'all_season'){
		race = 'All seasons (all time) ';
		if($('#general_all_season_series').val() != 'all'){
			race +='series: '+ $('#general_all_season_series').val() + ' - ';
		}
	}

	var text = "";
	if($('#general_time__toggle').is(":checked")){
		text += 'time perecentage: '+$('#general_time').val()+ '% of winning time';
	}

	if($('#general_rank__toggle').is(":checked")){
		var men_rank = 'Does not apply to men';
		if($('#general_rank_men').val().length){
			men_rank = $('#general_rank_men').val()+ ' (Men)';
		}
		var women_rank = 'Does not apply to women';
		if($('#general_rank_women').val().length){
			women_rank = $('#general_rank_women').val() + ' (Women)';
		}
		
		text += 'and finish at a rank (or better): '+men_rank+'  or  '+women_rank+' ';

		
	}

	if($('#general_nettime__toggle').is(":checked")){
		var men_nettime = 'Does not apply to men';
		if($('#general_nettime_men').val().length){
			men_nettime = $('#general_nettime_men').val()+ '(Men)';
		}
		var women_nettime = 'Does not apply to women';
		if($('#general_nettime_women').val().length){
			women_nettime = $('#general_nettime_women').val() + '(Women)';
		}
		
		text += 'and finish at a better time than: '+men_nettime+'  or  '+women_nettime+' ';
	}
	if($('#general_distance__toggle').is(":checked")){
		general_nr_of_times_show_criteria = false;
		text += 'and ski atleast '+$('#general_distance').val()+' km ';
	}
	if($('#general_amount__toggle').is(":checked")){
		general_nr_of_times_show_criteria = false;

		text += 'and finish this amount of races: '+$('#general_amount').val()+ ' ';
	}
	if($('#general_series__toggle').is(":checked")){
		general_nr_of_times_show_criteria = false;

		text += 'and this series: '+$('#general_series').val()+ ' ';
	}

	if($('#general_fastest_climber__toggle').is(":checked")){
		general_nr_of_times_show_criteria = false;

		var men_climb_rank = 'Does not apply to men';
		if($('#climber_rank_men').val().length){ 
			men_climb_rank = $('#climber_rank_men').val()+ ' (Men)';
		}
		var women_climb_rank = 'Does not apply to women';
		if($('#climber_rank_women').val().length){
			women_climb_rank = $('#climber_rank_women').val() + ' (Women)';
		}
		
		text += 'and finish at a better CLIMB-place than: '+men_climb_rank+'  or  '+women_climb_rank+' ';

	}
	if($('#general_fastest_sprinter__toggle').is(":checked")){
		general_nr_of_times_show_criteria = false;

		var men_sprinter_rank = 'Does not apply to men';
		if($('#sprinter_rank_men').val().length){ 
			men_sprinter_rank = $('#sprinter_rank_men').val()+ ' (Men)';
		}
		var women_sprinter_rank = 'Does not apply to women';
		if($('#sprinter_rank_women').val().length){
			women_sprinter_rank = $('#sprinter_rank_women').val() + ' (Women)';
		}
		
		text += 'and finish at a better SPRINTER-place than: '+men_sprinter_rank+'  or  '+women_sprinter_rank+' ';
	}
	

	if(general_nr_of_times_show_races && general_nr_of_times_show_criteria){
		$('#general_nr_of_times_div').removeClass('hide');
		$('#store_nr_of_times').prop('checked', true);
		
		if($('#general_nr_of_times').val() && $('#general_nr_of_times').val() > 1){
			text = text.slice(0, -1); 
			text += ', this must be achieved '+$('#general_nr_of_times').val()+ ' times ';	
		}
		

	}else{
		$('#store_nr_of_times').prop('checked', false);

	}

	text = text.slice(4, -1);
	$('#general-pin--description').html(race+text);
}

function event_listeners(){
	event_listeners_ranking();
	info_toggler_event_listener();

	
	$('#category_filter').on('change', function(){
		if($(this).prop('checked')){
			$('.category-filter-container > .chevron').addClass('active');
		}else{
			$('.category-filter-container > .chevron').removeClass('active');
		}
	})



	$('label[for="expand_stats"]').on('click', function(){
		var for_attr = $(this).attr('for');
		$(this).toggleClass('active'); 
		var checkBox = $('input[type="checkbox"][name="'+for_attr+'"]');
		if($(this).hasClass('active')){
			$(this).find('span:nth-child(2)').html('HIDE')
			checkBox.prop("checked", true);	
		}else{
			$(this).find('span:nth-child(2)').html('SHOW')
			checkBox.prop("checked", false);
		}



	})


	if($('#general_pin_type').length){
		general_pin_criteria_text();
		toggle_criteria_labels();

		$('#sprinter_rank_women, #sprinter_rank_men, #climber_rank_men, #climber_rank_women, #general_fastest_climber__toggle, #general_fastest_sprinter__toggle, #general_race_ref_comparison, #general_race_comparison, #general_nr_of_times, #general_season_series, #general_all_season_series, #general_time,#general_rank_women, #general_rank_men, #general_rank, #general_nettime, #general_nettime_men, #general_nettime_women, #general_distance, #general_amount, #general_series, select[name="general_race[]"], select[name="general_race_ref[]"], #season, #general_pin_type, #general_rank__toggle, #general_time__toggle, #general_nettime__toggle, #general_distance__toggle, #general_amount__toggle, #general_series__toggle').on('change', function(){
			general_pin_criteria_text();
			toggle_criteria_labels();
		});
		$('.general_pin_container').addClass('text--hide');

		$('.general_pin_container[data-general~="'+$('#general_pin_type').val()+'"]').removeClass('text--hide');

		$('#general_pin_type').on('change', function(){
			$('.general_pin_container').addClass('text--hide');
			$('.general_pin_container[data-general~="'+$(this).val()+'"]').removeClass('text--hide');

		});

	}

	$('.cookie-notification--close').on('click', function (){
		$('.cookie-notification').removeClass('active');
		createCookie('cookie-notification', false, 100);

	});



	$('label.category--label').on('click', function(e){
		var target = window[this.htmlFor];
		target.checked = !target.checked;
		e.preventDefault();
		var value = $(this).attr('for');
		$('label.category--label').removeClass('active');
		$('.category--label[for="'+value+'"]').addClass('active');

	})



	$('.category-filter--toggle').on('click', function(e){
		var that = this;
		$(this).toggleClass('active');

		if($(this).hasClass('active') && $(this).attr('data-cat-name') != 'all'){
			$('.category-filter--toggle[data-cat-name="all"]').removeClass('active');
		}
		


		if($('.category-filter--toggle.active').length != 0 && $(this).attr('data-cat-name') != 'all' ){
			$('.category-filter').addClass('hide--category');
			var category_string = "";
			$('.category-filter--toggle.active').each(function() {
				var cat_id = $(this).attr('data-cat-name');
				category_string += cat_id+", ";

				$('.category-filter[data-cat-name*="'+cat_id+'"]').removeClass('hide--category');
			});
			$('.category-filter-label').html('<span>Filter ('+category_string.substring(0, category_string.length - 2)+')</span>')
			$('.category-filter-label').addClass('active');
		}else if($(this).attr('data-cat-name') == 'all'){
			$('.category-filter--toggle').removeClass('active');
			$('.category-filter').removeClass('hide--category');
			$('.category-filter-label').html('<span>Filter</span>')
			$('.category-filter-label').removeClass('active');
			$(this).addClass('active');

		}else{
			$('.category-filter').removeClass('hide--category');
			$('.category-filter-label').html('<span>Filter</span>')
			$('.category-filter-label').removeClass('active');
			$('.category-filter--toggle[data-cat-name="all"]').addClass('active');



		}
	});

	$('.button-close--message, .message-box__overlay ').on('click', function(){
		$('.message-box--modal, .message-box__overlay').addClass('hide');
	});

	var typingTimer;                //timer identifier
	var doneTypingInterval = 1000;  //time in ms (5 seconds)
		//on keyup, start the countdown
		$('#filter_search').keyup(function(){

			clearTimeout(typingTimer);

			typingTimer = setTimeout(function(e){
				var searchword = $('#filter_search').val();
				if(searchword.length){
					$('.searchword-filter').addClass('hide--searchword');
					$('.searchword-filter[data-searchword*="'+searchword.toLowerCase()+'"]').removeClass('hide--searchword');
				}else{
					$('.searchword-filter').removeClass('hide--searchword');	
				}


			}, doneTypingInterval);

		});
		$(document).on('click', '.delete-form', onSubmitFormAttribute);


		function onSubmitFormAttribute(e) {
			e.preventDefault();


			if ($(this).data('delete') == true) {

				if (confirm("You are about to permanently delete the selected items.\n'Cancel' to stop, 'OK' to delete.")) {
					$(this).submit();
				}
			}else if($(this).data('action') == true){
				if (confirm("You are about to change the selected items.\n'Cancel' to stop, 'OK' to change.")) {
					$(this).submit();
				}
			}
			else {
				$(this).submit();
			}

		}



		function readURL(input) {


			
			$('label[for="'+$(input).attr('id')+'"] .image-field-fallback').addClass('hide');
			if (input.files && input.files[0]) {
				var reader = new FileReader();

				reader.onload = function(e) {

					if($('#image-preview').length){
						console.log(input);
						$('#image-preview').parents('.profile__img-wrapper.hide').removeClass('hide');
						$('#image-preview').removeClass('hide');
						$('#image-preview').attr('src', e.target.result);	
					}else{
						$('.uploadimg').attr('src', e.target.result);	
					}


				}

				reader.readAsDataURL(input.files[0]);
			}
		}

		$("input[type='file']").change(function() {
			readURL(this);
		});

		$('.image-remove').on('click', function(){
			var id = $(this).attr('data-id')
			$(this).addClass('hide');
			$('.img[data-id="'+id+'"]').addClass('hide');
			$('label[for="'+id+'"] .image-field-fallback').removeClass('hide');
			$('#image-preview[data-id="'+id+'"]').attr('src', '');
			$('input[id="'+id+'_remove"]').val('remove');


		})



	}



