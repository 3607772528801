document.addEventListener('DOMContentLoaded', async () => {
    const ruleContainer = document.querySelector('.sc-membership');
    if (ruleContainer) {
        getTags();
        setButtons();
    }
});

async function getTags() {
    try {
        const response = await fetch('/rule/get_tags', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const tags = data.tags.map(tag => tag.id);

        await Promise.all(
            Array.from(document.querySelectorAll('.membership-toggle')).map(async div => {
                const tagId = parseInt(div.id);
                const isActive = tags.includes(tagId);
                updateClasses(div, isActive ? 'off' : 'on');
            })
        );

    } catch (error) {
        console.error('Error fetching tags:', error);
    }
}

async function updateClasses(parent, toggleType) {
    const onButton = parent.querySelector('[data-toggle="on"]');
    const offButton = parent.querySelector('[data-toggle="off"]');

    if (toggleType === 'on') {
        onButton.classList.add('sc-button__grey');
        offButton.classList.remove('sc-button__grey');
    } else {
        offButton.classList.add('sc-button__grey');
        onButton.classList.remove('sc-button__grey');
    }
}

async function setButtons() {
    document.querySelectorAll('.sc-membership-toggle').forEach(button => {
        button.addEventListener('click', function () {
            var parent = this.closest('.col-4');
            toggleLoadingState(parent, true);
            
            const toggleType = this.getAttribute('data-toggle'); // 'on' or 'off'

            if(toggleType == 'on') {
                addTagToUser(parent);
            } else {
                removeTagFromUser(parent);
            }
            
        });
    });
}

function addTagToUser(parent) {
    fetch(`/rule/add_tag/${parent.id}`, {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(async data => {
            await getTags();
            toggleLoadingState(parent, false);
        })
        .catch(error => {
            console.error('Error adding tag:', error);
        });

}

function removeTagFromUser(parent) {
    fetch(`/rule/remove_tag/${parent.id}`, {
        method: 'DELETE',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(async data => {
            await getTags();
            toggleLoadingState(parent, false);
        })
        .catch(error => {
            console.error('Error removing tag:', error);
        });

}

function toggleLoadingState(parent, isLoading) {
    const spinner = parent.querySelector('.spinner-border');
    const buttons = parent.querySelectorAll('.sc-button');
    if (isLoading) {
        spinner.classList.remove('d-none');
        buttons.forEach(button => button.classList.add('d-none'));
    } else {
        spinner.classList.add('d-none');
        buttons.forEach(button => button.classList.remove('d-none'));
    }
}