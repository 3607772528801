
/*
membership-display ska visa vilken membership man har
*/
document.addEventListener('DOMContentLoaded', async () => {
    if (!$(location).attr('href').includes('login')) {
        var sesamy = await initializeSesamy();
        const sesamyContainer = document.querySelector('.sc-membership');
        if (sesamyContainer) {
            setUpMembershipPage(sesamy);
        }
    }
});

async function setUpMembershipPage(sesamy) {
    await fetchContracts(sesamy);
    await fetchPayments(sesamy);
}

async function initializeSesamy() {
    try {
        const response = await fetch('/sesamy/config');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const config = await response.json();
        var sesamy = await sesamyJs.init({ clientId: config.clientId });
        // const isAuth = await sesamy.auth.isAuthenticated();
        const accessToken = window.sesamyToken;
        const expiresIn = 3600; // 1 hour in seconds

        await setAuthToken(sesamy, accessToken, expiresIn);
        await setSubscriptionStatus(sesamy);
        return sesamy;

    } catch (error) {
        console.error('Initialization failed:', error);
        // window.location.href = '/logout';
    }
}

async function setAuthToken(sesamy, accessToken, expiresIn) {
    try {
        await sesamy.auth.setToken(accessToken, expiresIn);
    } catch (error) {
        throw error;
    }
}

async function setSubscriptionStatus(sesamy) {
    try {
        var contract = await sesamy.contracts.list();
        var status = 'INACTIVE';
        if(contract) {
            status = 'ACTIVE';
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/set-membership',
            method: 'POST',
            data: {
                MembershipStatus: status,
            }
        });
    } catch (error) {
        console.error('Error: ', error);
    }
}

async function fetchContracts(sesamy) {
    try {
        const contracts = await sesamy.contracts.list();
        if(contracts) {
            const details = document.querySelector(".details-sesamy");
            details.href = contracts[0].manageUrl;
            details.classList.remove('d-none')
            const created = new Date(contracts.createdAt);
            const nextbill = new Date(contracts.nextBillAt);
            const isMonthly = (nextbill.getFullYear() === created.getFullYear() &&
                       nextbill.getMonth() === created.getMonth() + 1) ||
                      (nextbill.getFullYear() === created.getFullYear() + 1 &&
                       nextbill.getMonth() === 0 && created.getMonth() === 11);
    
            if (isMonthly) {
                $('.membership-display').text('MONTHLY');
            } else {
                $('.membership-display').text('YEARLY');
            }
        } else {
            notMember();
        }

    } catch (error) {
        notMember();
        // console.error('Error fetching contracts: ', error);
    }
}

async function fetchPayments(sesamy) {
    var payments = null;
    let paymentContainer = $('.sc-membership__payments');
    paymentContainer.empty();
    try {
        payments = await sesamy.bills.list();
        if(payments.length > 0) {
            payments.forEach(function(payment) {
                var formattedDate = new Date(payment.createdAt)
                    .toLocaleString("en-US", { month: "long" })
                    .toUpperCase() + 
                    " " + 
                    new Date("2024-12-04T14:33:23.018Z").getFullYear().toString().slice(-2);
                paymentContainer.append(`
                    <div class="row sc-membership__payments__payment">
                        <div class="sc-membership__payments__payment__date">${formattedDate}<span>PAID</span></div>
                        <div class="sc-membership__payments__payment__info">
                            <img src="/images/logos/langd_mobile.svg">
                            <span>YEARLY</span> 
                            <a href="${payment.receiptUrl}" target="_blank" class="sc-button">SEE RECIEPT</a>
                        </div>
                    </div>
                `)
            })
            paymentContainer.append(`
                <!-- <a href="#">PAYMENT METHOD</a> -->
           `)
        } else {
            paymentContainer.append(`
                <div class="row sc-membership__payments__payment">
                    <div class="sc-membership__payments__payment__date"><span></span></div>
                    <div class="sc-membership__payments__payment__info">
                        <img src="/images/logos/langd_mobile.svg">
                        <span>YOU HAVE NOT MADE ANY PAYMENTS WITH THIS ACCOUNT</span> 
                    </div>
                </div>
            `);
        }
    } catch (error) {
        // console.error('Error fetching bills, ', error)
    }

}

function notMember() {
    $('.membership-display').text('NOT ACTIVE');
    $('.details-sesamy').text('BECOME A MEMBER HERE');
    $('.details-sesamy').removeClass('d-none');
    $('.info-container').removeClass('d-none');
    
}
